/* footer */
footer {
    background-color: #e40001;;
    font-size: 16px;
    color: #e3a499;
    padding-top: 75px;
    z-index: 1;
    > .container {
        display: flex;
        justify-content: space-between;
        &:after, &:before {
            display: none;
        }
    }
    h4 {
        font-family: $medium;
        color: #fefefe;
        margin: 0 0 40px 0;
    }
    p {
        margin: 0;
    }
    ul {
        @include ul-default;
        li {
            margin-bottom: 10px;
            a {
                color: #facbcb;
                &:focus {
                    color: #facbcb;
                }
                &:hover {
                    text-decoration: none;
                    color: #fefefe;
                }
            }
            &.active {
                a {
                    color: #fefefe;
                }
            }
        }
    }
    .footer__nav {
        li {
            &:last-of-type {
                margin-bottom: 0;
            }
            a {
                color: #facbcb;
            }
        }
    }
    .footer__col {
        padding: 0 90px;
        border-right: 1px solid #f28080;
        &:first-of-type {
            padding-left: 0;
        }
        &:last-of-type {
            padding-right: 0;
            border-right: none; 
        }
    }
    .footer__nav--list {
        margin-top: 50px;
        ul {
            li {
                @include inline-block;
                margin-right: 40px;
                a {
                    display: block;
                    color: #facbcb;
                    font-family: $medium;
                    text-transform: uppercase;
                    &:hover {
                        color: #000000;
                    }
                }
                &.active a {
                    color: #000;
                }
            }
        }
    }
    .socials {
        display: flex;
        position: relative;
        li {
            @include inline-block;
            margin-right: 18px;
            margin-bottom: 0;
            a {
                display: block;
                width: 40px;
                height: 40px;
                font-size: 24px;
                color: #facbcb;
                text-align: center;
                position: relative;
                border: 1px solid #facbcb;
                box-sizing: border-box;
                i {
                    position: absolute;
                    top: 50%;
                    left: 0;
                    right: 0;
                    transform: translateY(-50%);
                    line-height: 1;
                }
                .fa-paper-plane {
                    font-size: 20px;
                }
                .fa-vk {
                    top: 45%;
                }
                &:hover {
                    color: #fff;
                    border: 1px solid #fff;
                }
            }
            &:last-child {
                margin-right: 0;
            }
        }
    }
    .footer__contacts {
        .footer__contacts__list {
            li {
                color: #facbcb;
                margin-bottom: 25px; 
                &:last-of-type {
                    margin-bottom: 0;
                }
            }
        }
    }
    .container-fluid {
        background-color: #000000;
        color: #fff;
        padding: 38px 0;
        margin-top: 65px;
        p {
            @include inline-block;
            span {
                color: #e40001;
            }
        }
        .artmedia {
            @include inline-block;
            color: #ffffff;
            text-decoration: none;
            float: right;
            position: relative;
            padding-right: 135px;
            img {
                position: absolute;
                right: 0;
                top: -3px;
            }
        }
    }
    @media screen and (max-width: 1199px) {
        .footer__nav--list {
            ul li {
                margin-right: 30px;
            }
        }
        .footer__col {
            width: 100%;
            padding: 0 50px;
        }
    }
    @media screen and (max-width: 991px) {
        padding: 35px 0 0 0;
        h4 {
            margin-bottom: 20px;
        }
        .footer__nav--list {
            margin: 15px 0 15px 0;
            ul li {
                margin-right: 15px;
            }
        }
        .footer__col {
            padding: 0 20px;
        }
        .footer__contacts {
            .footer__contacts__list li {
                margin-bottom: 15px;
            }
            .socials {
                padding-top: 30px;
                margin-top: 20px;
            }
        }
        .container-fluid {
            padding: 25px 0;
            margin-top: 25px;
        }
    }
    @media screen and (max-width: 767px) {
        padding-top: 20px;
        h4 {
            margin-bottom: 10px;
        }
        .cat--title {
            display: none;
        }
        > .container {
            flex-wrap: wrap;
        }
        .footer__col {
            width: 100%;
            padding: 0;
            border: none;
            margin: 10px 0;
        }
    }
    @media screen and (max-width: 680px) {
        .container-fluid {
            padding: 20px 0;
            p {
                display: block;
                text-align: center;
            }
            .artmedia {
                display: table;
                float: none;
                margin: 5px auto 0;
            }
        }
        .footer__nav {
            display: none;
        }
    }
}
/* footer */