/* Connection Font Icons */
@font-face {
    font-family: 'FontAwesome';
    src: url('../fonts/fontawesome-webfont.eot');
    src: url('../fonts/fontawesome-webfont.eot') format('embedded-opentype'), 
    url('../fonts/fontawesome-webfont.woff2') format('woff2'), 
    url('../fonts/fontawesome-webfont.woff') format('woff'), 
    url('../fonts/fontawesome-webfont.ttf') format('truetype'), 
    url('../fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular') format('svg');
    font-weight: 400;
    font-style: normal
}
/* Connection Font Icons */

/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on February 13, 2018 */
@font-face {
    font-family: 'pf_agora_sans_probold';
    src: url('../fonts/agorasansprobold-webfont.eot');
    src: url('../fonts/agorasansprobold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/agorasansprobold-webfont.woff2') format('woff2'),
         url('../fonts/agorasansprobold-webfont.woff') format('woff'),
         url('../fonts/agorasansprobold-webfont.ttf') format('truetype'),
         url('../fonts/agorasansprobold-webfont.svg#pf_agora_sans_probold') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'pf_agora_sans_proitalic';
    src: url('../fonts/agorasansproitalic-webfont.eot');
    src: url('../fonts/agorasansproitalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/agorasansproitalic-webfont.woff2') format('woff2'),
         url('../fonts/agorasansproitalic-webfont.woff') format('woff'),
         url('../fonts/agorasansproitalic-webfont.ttf') format('truetype'),
         url('../fonts/agorasansproitalic-webfont.svg#pf_agora_sans_proitalic') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'pf_agora_sans_prolight';
    src: url('../fonts/agorasansprolight-webfont.eot');
    src: url('../fonts/agorasansprolight-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/agorasansprolight-webfont.woff2') format('woff2'),
         url('../fonts/agorasansprolight-webfont.woff') format('woff'),
         url('../fonts/agorasansprolight-webfont.ttf') format('truetype'),
         url('../fonts/agorasansprolight-webfont.svg#pf_agora_sans_prolight') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'pf_agora_sans_promedium';
    src: url('../fonts/agorasanspromedium-webfont.eot');
    src: url('../fonts/agorasanspromedium-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/agorasanspromedium-webfont.woff2') format('woff2'),
         url('../fonts/agorasanspromedium-webfont.woff') format('woff'),
         url('../fonts/agorasanspromedium-webfont.ttf') format('truetype'),
         url('../fonts/agorasanspromedium-webfont.svg#pf_agora_sans_promedium') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'pf_agora_sans_proregular';
    src: url('../fonts/agorasansproregular-webfont.eot');
    src: url('../fonts/agorasansproregular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/agorasansproregular-webfont.woff2') format('woff2'),
         url('../fonts/agorasansproregular-webfont.woff') format('woff'),
         url('../fonts/agorasansproregular-webfont.ttf') format('truetype'),
         url('../fonts/agorasansproregular-webfont.svg#pf_agora_sans_proregular') format('svg');
    font-weight: normal;
    font-style: normal;
}


$regular: 'pf_agora_sans_proregular';
$bold: 'pf_agora_sans_probold';
$light: 'pf_agora_sans_prolight';
$italic: 'pf_agora_sans_proitalic';
$medium: 'pf_agora_sans_promedium';

@mixin inline-block {
	display: inline-block;
	vertical-align: top;
}
@mixin ul-default {
	list-style: none;
	margin: 0;
	padding: 0;
}

*:after, *:before {
	display: block;
	line-height: 1;
}

body {
	display: flex;
	height: 100vh;
	position: relative;
	flex-direction: column;
	font-family: $regular;
    color: #454545;
    padding-top: 150px;
	margin: 0;
    @media screen and (max-width: 991px) {
        padding-top: 94px;
    }
    @media screen and (max-width: 767px) {
        padding-top: 78px;
    }
}

h1 {
	font-family: $regular;
	font-size: 40px;
	text-transform: uppercase;
	color: #fff;
	line-height: 1;
	margin: 0 0 40px 0;
	@media screen and (max-width: 1199px) {
		font-size: 32px;
        margin-bottom: 30px;
	}
	@media screen and (max-width: 991px) {
		font-size: 28px;
        margin-bottom: 20px;
	}
    @media screen and (max-width: 767px) {
        font-size: 24px;
        margin-bottom: 15px;
    }
}
h2 {
	font-family: $regular;
	font-size: 38px;
	color: #454545;
	margin: 0 0px 40px;	
	a {
		font-family: $regular;
		font-size: 38px!important;
		color: #454545;
        &:hover {
            color: #e40001!important;
            text-decoration: none;
        }
	}
	@media screen and (max-width: 1199px) {
		font-size: 30px;
        margin-bottom: 30px;
		a {
			font-size: 30px!important;
		}
	}
	@media screen and (max-width: 991px) {
		font-size: 26px;
        margin-bottom: 20px;
		a {
			font-size: 26px!important;
		}
	}
    @media screen and (max-width: 991px) {
        font-size: 22px;
        margin-bottom: 15px;
        a {
            font-size: 22px!important;
        }
    }
}
h3 {
	font-family: $regular;
	font-size: 32px;
	color: #454545;
	@media screen and (max-width: 1199px) {
		font-size: 26px;
	}
    @media screen and (max-width: 991px) {
        font-size: 22px;
    }
    @media screen and (max-width: 767px) {
        font-size: 20px;
    }
}
h4 {
    font-family: $regular;
	font-size: 20px;
	color: #454545;
    @media screen and (max-width: 991px) {
        font-size: 18px;
    }
}
h5 {
    font-family: $regular;
	font-size: 18px;
	color: #454545;
}
h6 {
    font-family: $regular;
	font-size: 16px;
	color: #454545;
}
a {
	transition: 350ms;
	text-decoration: none;
    color: #454545;
	&:focus {
		color: #454545;
		text-decoration: none;
	}
	&:hover {
		text-decoration: underline;
        color: #e40001;
	}
}
textarea, button, select, input[type="text"], input[type="email"], input[type="search"], input[type="password"] {
	border-radius: 0px;
	-webkit-appearance: none;
}
.main, .page {
	position: relative;
	flex: 1 0 auto;
	font-size: 16px;
}
.main, .page, header {
    textarea,
    input[type="text"],
    input[type="email"],
    input[type="password"] {
        min-height: 48px;
        width: 100%;
        background-color: #f1f1f1;
        font-family: $regular;
        font-size: 16px;
        padding: 14px 20px;
        margin-bottom: 20px;
        border: none;
        outline: none;
    }
    @media screen and (max-width: 991px) {
        textarea,
        input[type="text"],
        input[type="email"],
        input[type="password"] {
            min-height: 40px;
            padding: 10px 15px;
        }
    }
    @media screen and (max-width: 767px) {
        textarea,
        input[type="text"],
        input[type="email"],
        input[type="password"] {
            min-height: 36px;
            padding: 8px 15px;
        }
    }
}
.main {
	.container {
		padding-top: 80px;
		padding-bottom: 80px;
		@media screen and (max-width: 1199px) {
			padding-top: 50px;
			padding-bottom: 50px;
		}
		@media screen and (max-width: 991px) {
			padding-top: 25px;
			padding-bottom: 25px;
		}
	}
}
.btn {
	display: table;
	width: auto!important;
    height: 54px;
	background-color: #e40001;
	font-family: $bold!important;
	font-size: 16px;
	color: #ffffff!important;
    text-transform: uppercase;
	cursor: pointer;
	border-radius: 0px;
	border: 2px solid #e40001;
	padding: 15px 35px!important;
    line-height: 1;
	transition: 300ms;
	&:focus, &:active {
		background-color: #e40001;
		color: #fff;
	}
	&:hover, &.active {
		background-color: #fff!important;
		color: #e40001!important;
	}	
	@media screen and (max-width: 991px) {
		height: 40px;
        padding: 10px 20px!important;
	}
}

.check--list {
	@include ul-default;
    margin: 15px 0;
	li {
		display: block;
		position: relative;
		font-size: 16px;
		padding-left: 25px;
		margin: 7px 0;
		&:before {
			content: '\f0ac';
			position: absolute;
			font-family: 'fontAwesome';
			font-size: 16px;
			color: #e40001;
			left: 2px;
			top: 2px;
		}
	}
}

.col--location,
.col--phone,
.col--email {
	position: relative;
	padding-left: 25px;
	font-size: 16px;
	&:after {
		content: '';
		font-family: 'fontAwesome';
		font-size: 20px;
		color: #304fbf;
		position: absolute;
		top: -2px;
		left: 0;
	}
}
.col--location:after {
	content: '\f041';
}
.col--phone:after {
	content: '\f095';
}
.col--email:after {
	content: '\f0e0';
	font-size: 16px;
	top: 2px;
}

.close {
    &:hover {
        &:before {
            transform: rotate(135deg)!important;
        }
        &:after {
            transform: rotate(45deg)!important;
        }
    }
}

.phones--num {
    pointer-events: none;
    @media screen and (max-width: 1024px) {
        pointer-events: auto;            
    }        
}


/* header */
header {
    color: #fff;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    z-index: 9;
    ul {
        @include ul-default;
    }
    .up {
        height: 150px;
        background-color: #e40001;
        .container {
            position: relative;
        }
    }
	/* header logo */
	.logo {
        display: table;
        text-decoration: none;
        line-height: 1;
        margin: 30px auto;
        img {
            display: block;
            max-width: 100%;
            margin: 0 auto;
        }
        .slogan {
            display: block;
            font-family: $medium;
            font-size: 20px;
            color: #fff;
            text-align: center;
            margin-top: 10px;
        }
    }
	/* header logo */

    /* header langs */
    .langs {
        width: 70px;
        min-height: 50px;
        max-height: 50px;
        background-color: #e40001;
        border: 1px solid #fff;
        position: absolute;
        left: 15px;
        top: 50px;
        padding-top: 13px;
        transition: 300ms;
        overflow: hidden;
        z-index: 1;
        &:after {
            content: '';
            width: 24px;
            height: 24px;
            background: url(../img/network.svg) no-repeat center center;
            background-size: contain;
            position: absolute;
            top: 11px;
            left: 10px;
        }
        ul {
            @include ul-default;
            li {
                padding-left: 40px;
                margin-bottom: 5px;
                a {
                    font-size: 16px;
                    color: #fefefe;
                    text-decoration: underline;
                    &:hover {
                        text-decoration: none;
                    }
                }
                &.active {
                    margin-bottom: 12px;
                    a {
                        text-decoration: none;
                    }
                }
            }
        }
        &:hover {
            max-height: 150px;
        }
    }
    /* header langs */

    /* header search */
    .search {
        position: absolute;
        left: 105px;
        top: 50px;
        height: 50px;
        width: 0px;
        padding-left: 0px;
        overflow: hidden;
        border: 1px solid transparent;
        box-sizing: border-box;
        transition: 400ms;
        z-index: 1;
        &.open {
            width: 270px;
            padding-left: 50px;
            border: 1px solid #fff;
        }
        input[type="search"] {
            width: 100%;
            height: 100%;
            font-size: 16px;
            color: #fff;
            background-color: #e40001;
            padding: 15px 20px;
            border: none;
            box-sizing: border-box;
            outline: none;
            &::placeholder {
                font-family: $italic;
                color: rgba(255,255,255,0.7);
            }
        }
        .close {
            display: block;
            width: 50px;
            height: 100%;
            background-color: #e40001;
            border-right: 1px solid #fff;
            position: absolute;
            top: 0px;
            left: 0px;
            opacity: 1;
            &:before, &:after {
                content: '';
                width: 30px;
                height: 2px;
                background-color: #fff;
                position: absolute;
                top: 50%;
                left: 0;
                right: 0;
                margin: -1px auto 0;
                transition: 300ms;
            }
            &:before {
                transform: rotate(45deg);
            }
            &:after {
                transform: rotate(-45deg);
            }
            &:hover {
                &:before {
                    transform: rotate(135deg);
                }
                &:after {
                    transform: rotate(45deg);
                }
            }
        }
    }
    .search--btn {
        display: block;
        width: 50px;
        height: 50px;
        font-family: 'fontAwesome';
        font-size: 24px;
        color: #fff;
        text-align: center;
        text-decoration: none;
        position: absolute;
        left: 105px;
        top: 50px;
        padding-top: 10px;
        border: 1px solid #fff;
        box-sizing: border-box;
        &:after {
            content: '\f002';
        }
    }
    /* header search */

    /* header login */
    .login {
        position: absolute;
        right: 15px;
        top: 50px;
        > a {
            @include inline-block;
            height: 50px;
            font-size: 16px;
            color: #fff;
            position: relative;
            margin-left: 18px;
            box-sizing: border-box;
        }
        .cart {
            width: 60px;
            background: transparent url(../img/shopping-basket.svg) no-repeat center center;
            background-size: 28px;
            border: 1px solid #fff;
            margin-left: 0;
            line-height: 1;
            span {
                display: block;
                width: 20px;
                height: 20px;
                background-color: #fff;
                font-family: $bold;
                font-size: 12px;
                color: #e40001;
                text-align: center;
                border-radius: 100%;
                position: absolute;
                top: 7px;
                right: 5px;
                padding-top: 4px;
                box-sizing: border-box;
            }
            &:hover {
                opacity: 0.7;
            }
        }
        .login__in {
            width: 120px;
            text-align: center;
            text-decoration: none;
            padding: 12px 45px 0px 5px;
            border: 1px solid #fff;
            &:after {
                content: '';
                width: 40px;
                background: url(../img/avatar.png) no-repeat center center;
                background-size: 20px;
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                border-left: 1px solid #fff;
                transition: 300ms;
            }
            &:hover {
                &:after {
                    background-image: url(../img/avatar1.png);
                    background-repeat: no-repeat;
                    background-color: #fff;
                    background-size: 20px;
                }
            }
        }
        .login__name {
            height: auto;
            max-width: 125px;
            text-decoration: underline;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            margin-top: 13px;
            &:hover {
                text-decoration: none;
            }
        }
        .login__out {
            width: 40px;
            background:  url(../img/exit.png) no-repeat center center;
            background-size: 25px;
            border: 1px solid #fff;
            &:hover {
                background: #fff url(../img/exit1.png) no-repeat center center;
                background-size: 25px;
            }
        }
        .login__block {
            display: none;
            width: 380px;
            background-color: #fff;
            position: absolute;
            top: 100px;
            right: 0;
            padding: 25px 40px 20px;
            box-shadow: 0px 0px 15px rgba(0,0,0,0.5);
            line-height: 1;
            box-sizing: border-box;
            z-index: 1;
            &:after {
                content: '';
                position: absolute;
                top: -7px;
                right: 50px;
                border-bottom: 7px solid #fff;
                border-left: 6px solid transparent;
                border-right: 6px solid transparent;
            }
            span {
                display: block;
                font-family: $medium;
                font-size: 28px;
                color: #454545;
                text-align: center;
                margin-bottom: 15px;
            }
            form {
                display: block;
                text-align: right;
                .g-recaptcha {
                    margin-bottom: 15px;
                }
                .btn {
                    width: 100%!important;
                    margin-bottom: 15px;
                }
            }
            a {
                @include inline-block;
                font-size: 18px;
                color: #787878;
                text-decoration: underline;
                margin: 0 0 10px;
                &:hover {
                    text-decoration: none;
                }
            }
            .close {
                width: 25px;
                height: 25px;
                position: absolute;
                top: 8px;
                right: 8px;
                margin: 0;
                opacity: 1;
                &:before, &:after {
                    content: '';
                    width: 100%;
                    height: 2px;
                    background-color: #e40001;
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 50%;
                    margin: -1px auto 0;
                    transform: rotate(45deg);
                    transition: 300ms;
                }
                &:after {
                    transform: rotate(-45deg);
                }
            }
        }
    }
    /* header login */

	/* header nav */
	.nav {
        font-size: 0;
        > ul > li {
            @include inline-block;
            position: relative;
            margin-right: 1px;
            > a {
                display: block;
                font-family: $medium;
                font-size: 18px;
                color: #fff;
                padding: 32px 22px;
                text-transform: uppercase;
                &:hover {
                    background-color: #fff;
                    color: #464646;
                    text-decoration: none;
                }
            }
            &.active > a {
                background-color: #fff;
                color: #464646;
            }
            ul {
                display: none;
                padding: 30px 30px 25px;
                position: absolute;
                top: 80%;
                left: 0;
                right: 0;
                z-index: 1;
                &:before {
                    content: '';
                    background-color: rgba(228, 0,1,0.7);
                    position: absolute;
                    top: 5px;
                    left: 0;
                    right: 0;
                    bottom: 0;
                }
                &:after {
                    content: '';
                    width: 0;
                    heigh: 0;
                    border-bottom: 5px solid #e40001;
                    border-left: 4px solid transparent;
                    border-right: 4px solid transparent;
                    position: absolute;
                    top: 0px;
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                }
                li {
                    position: relative;
                    padding: 9px 0;
                    a {
                        display: table;
                        font-family: $medium;
                        font-size: 14px;
                        text-transform: uppercase;
                        color: rgba(255,255,255,0.5);
                        margin: 0 auto;
                        &:hover {
                            color: #fff;
                            text-decoration: none;
                        }
                    }
                    &:after {
                        content: '';
                        height: 1px;
                        width: 70px;
                        border-bottom: 1px dashed #db897b;
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        right: 0;
                        margin: 0 auto;
                    }
                    &:first-child {
                        padding-top: 0px;
                    }
                    &:last-child {
                        padding-bottom: 0;
                        &:after {
                            display: none;
                        }
                    }
                    &.active a {
                        color: #fff;
                    }
                }
            }
        }
    }
	/* header nav */
    @media screen and (max-width: 1199px) {
        .login {
            .login__name {
                max-width: 100px;
            }
        }
    }
    @media screen and (max-width: 991px) {
        position: fixed;
        left: 0;
        right: 0;
        z-index: 9;
        box-shadow: 0px 0px 15px rgba(0,0,0,0.3);
        .up {
            height: auto;
        }
        .logo {
            width: 200px;
            margin: 15px auto;
            img {
                width: 200px;
            }
            .slogan {
                white-space: nowrap;
                font-size: 14px;
            }
        }
        .langs {
            min-height: 30px;
            max-height: 30px;
            width: auto;
            top: 51px;
            left: auto;
            right: 15px;
            padding-top: 5px;
            z-index: 2;
            &:after {
                display: none;
                width: 15px;
                height: 15px;
                top: 5px;
                left: 5px;
            }
            ul li {
                padding-left: 7px;
                padding-right: 7px;
                &.active {
                    margin-bottom: 6px;
                }
                a {
                    font-size: 14px;
                }
            }
        }
        .search--btn {
            height: 30px;
            width: 30px;
            font-size: 16px;
            top: 51px;
            left: auto;
            right: 56px;
            padding-top: 6px;
        }
        .search {
            height: 30px;
            top: 51px;
            left: auto;
            right: 56px;
            .close {
                width: 30px;
                &:before, &:after {
                    width: 20px;
                }
            }
            input[type="search"] {
                padding: 5px 15px;
            }
            &.open {
                padding-left: 30px;
            }
        }
        .login {
            top: 10px;
            > a {
                height: 30px;
                margin-left: 5px;
            }
            .cart {
                width: 40px;
                background-size: 16px;
                span {
                    width: 15px;
                    height: 15px;
                    font-size: 10px;
                    padding-top: 3px;
                    top: 2px;
                    right: 2px;
                }
            }
            .login__in {
                width: 30px;
                font-size: 0px;
                padding: 3px 29px 0 0px;
                &:after {
                    width: 30px;
                    background-size: 16px;
                }
                &:hover:after {
                    background-size: 16px;
                }
            }
            .login__out {
                width: 30px;
                background-size: 16px;
                &:hover {
                    background-size: 16px;
                }
            }
            .login__name {
                max-width: 70px;
                margin-top: 4px;
            }
            .login__block {
                width: 340px;
                top: 86px;
                padding: 20px 20px 15px;
            }
        }
        .nav {
            display: none;
            max-height: calc(100% - 94px);
            background-color: #e40001;
            position: fixed;
            top: 94px;
            left: 0;
            right: 0;
            padding: 10px 0;
            overflow: auto;
            z-index: 1;
            &:after {
                content: '';
                height: 1px;
                width: 720px;
                background-color: #fff;
                position: fixed;
                top: 95px;
                left: 0;
                right: 0;
                margin: 0 auto;
                z-index: 1;
            }
            > ul {
                max-width: 720px;
                margin: 0 auto;
                > li {
                    display: block;
                    text-align: center;
                    margin: 2px 0;
                    > a {
                        display: table;
                        margin: 0 auto;
                        font-size: 16px;
                        padding: 5px 10px;
                    }
                    ul {
                        position: relative;
                        top: 0;
                        left: 0;
                        padding: 10px 0;
                    }
                }
            }
        }
        .nav--btn {
            width: 35px;
            height: 30px;
            position: absolute;
            left: 15px;
            top: 33px;
            padding: 0;
            margin: 0;
            outline: none;
            border: 1px solid #fff;
            float: none;
            span {
                position: absolute;
                left: 0;
                right: 0;
                width: 75%;
                height: 3px;
                margin: 0 auto;
                background-color: #fff;
                transition: 350ms;
                &:nth-of-type(1) {
                    top: 6px;
                }
                &:nth-of-type(2) {
                    top: 13px;
                }
                &:nth-of-type(3) {
                    top: 20px;
                }
            }
            &.open {
                span {
                    &:nth-of-type(1) {
                        top: 13px;
                        transform: rotate(45deg);
                    }
                    &:nth-of-type(2) {
                        opacity: 0;
                    }
                    &:nth-of-type(3) {
                        top: 13px;
                        transform: rotate(-45deg);
                    }
                }
            }
        }
    }
    @media screen and (max-width: 767px) {
        .logo {
            img {
                width: 120px;
            }
        }
        .nav {
            top: 78px;
            max-height: calc(100% - 78px);
            &:after {
                width: calc(100% - 10px);
                left: 5px;
                right: 5px;
                top: 78px;
            }
        }
        .nav--btn {
            left: 5px;
            top: 25px;
        }
        .login {
            right: 5px;
            top: 5px;
            > a {
                font-size: 14px;
            }
            .login__in {
                padding-top: 5px;
            }
            .login__name {
                font-size: 14px;
            }
            .login__block {
                width: 320px;
                top: 73px;
                right: -5px;
                padding: 15px 20px 15px;
                &:after {
                    right: 15px;
                }
                .close {
                    top: 5px;
                    right: 5px;
                }
                form {
                    .g-recaptcha {
                        transform: scale(0.91);
                        transform-origin: 0 0;
                        margin-bottom: 10px;
                    }
                }
                span {
                    font-size: 24px;
                }
                a {
                    font-size: 16px;
                }
            }
        }
        .search--btn, .search {
            top: 42px;
            right: 45px;
        }
        .langs {
            top: 42px;
            right: 5px;
        }
    }
    @media screen and (max-width: 480px) {
        .login {
            .cart {
                width: 31px;
                background-position: 4px 7px;
                position: absolute;
                right: 0px;
                span {
                    padding-top: 3px;
                    font-size: 8px;
                }
            }
            .login__in {
                width: 29px;
                right: 35px;
                padding-right: 28px;
                &:after {
                    width: 29px;
                }
            }
            .login__name {
                max-width: 50px;
            }
            .login__out {
                right: 35px;
            }
            .login__name {
                right: 32px;
                display: none;
            }
        }
        .search--btn {
            right: 40px;
        }
        .search {
            right: 40px;
            &.open {
                width: calc(100% - 65px);
            }
        }
        .logo {
            margin: 18px auto;
            img {
                width: 110px;
            }
            .slogan {
                font-size: 10px;
            }
        }
        .nav {
            > ul > li > a {
                font-size: 14px;
            }
        }
    }
}
/* header */