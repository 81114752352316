/* main content */
.modal-open {
	overflow-y: visible;
	.modal {
		overflow: auto;
	}
}

.fancybox-enabled {
	overflow: visible;
	body {
		overflow-x: hidden;
	}
}

.banner {
    position: relative;
    overflow: hidden;
    padding-top: 90px;
    > img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
    }
    @media screen and (max-width: 991px) {
        padding-top: 0px;
    }
}

.page {
    .banner {
        height: 645px;
        &:after {
            content: '';
            background-color: rgba(0,0,0,0.3);
            position: absolute;
            left: 0;
            top: 90px;
            right: 0;
            bottom: 0;
            z-index: -1;
        }
        > img {
            height: auto;
        }
    }
    @media screen and (max-width: 1199px) {
        .banner {
            height: auto;
        }
    }
    @media screen and (max-width: 991px) {
        .banner {
            &:after {
                background-color: rgba(0,0,0,0.5);
                top: 0;
            }
        }
    }
}

/* main slider */
.slider {
	margin-bottom: 0px;
    position: relative;
    &:after {
        content: '';
        width: 100%;
        background-color: rgba(0,0,0,0.3);
        position: absolute;
        top: 0;
        right: 50%;
        margin-right: -120px;
        bottom: 0;
        z-index: 0;
    }
    .slick-list {
        z-index: 1;
    }
	.slick-slide {
        min-height: 500px;
		overflow: hidden;
		position: relative;
        z-index: 1;
		outline: none;
		img {
			display: block;
			width: 100%;
			height: auto;
		}
		.slider__title {
			display: block;
            max-width: 510px;
            font-family: $medium;
            font-size: 44px;
            color: #ffffff;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateY(-50%);
            margin-left: -500px;
            line-height: 1.2;
            z-index: 1;
            .line {
                display: block;
                width: 2px;
                background-color: #fff; 
                position: absolute;
                top: 0;
                bottom: 0;
                &:before, &:after {
                    content: '';
                    height: 2px;
                    width: 10px;
                    position: absolute;
                    background-color: #fff;
                }
                &:after {
                    bottom: 0;
                }
                &:first-of-type {
                    left: -70px;
                }
                &:last-of-type {
                    right: -70px;
                    &:before, &:after {
                        left: auto;
                        right: 0;
                    }
                }
            }
		}
	}
	.slick-dots {
		bottom: 30px;
		li {
			margin: 0px 2px;
			button {
				width: 15px;
				height: 15px;
				background-color: #efefef;
				border-radius: 100%;
				border: 1px solid #3361eb;
				&:before {
					display: none;
				}
				&:hover {
					background-color: #3361eb;
				}
			}
			&.slick-active {
				button {
					background-color: #3361eb;
				}
			}
		}
	}
    @media screen and (max-width: 1199px) {
        .slick-slide {
            min-height: 400px;
            .slider__title {
                max-width: 440px;
                font-size: 36px;
                margin-left: -430px;
                .line {
                    &:first-of-type {
                        left: -35px;
                    }
                    &:last-of-type {
                        right: -35px;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 991px) {
        .slick-slide {
            .slider__title {
                max-width: 350px;
                font-size: 30px;
                margin-left: -320px;
            }
        }
    }
    @media screen and (max-width: 767px) {
        &:after {
            left: 0;
            right: 0;
            margin: 0;
        }
        .slick-slide {
            min-height: 300px;
            .slider__title {
                max-width: 450px;
                left: 10px;
                right: 10px;
                font-size: 26px;
                text-align: center;
                padding: 0 25px;
                margin: 0 auto;
                .line {
                    &:first-of-type {
                        left: 0;
                    }
                    &:last-of-type {
                        right: 0;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 480px) {
        .slick-slide {
            min-height: 250px;
            .slider__title {
                font-size: 22px;
            }
        }
    }
}
/* main slider */

/* main cat */
.cat {
    font-size: 0;
    .cat__col {
        @include inline-block;
        width: 33.33%;
        height: 375px;
        position: relative;
        overflow: hidden;
        transition: color 0.25s;
        box-sizing: border-box;
        border-bottom: 3px solid #e40001;
        outline: none;
        /* &:before, &:after {
            content: '';
            border: 10px solid transparent;
            width: 0;
            height: 0;
            position: absolute;
            top: 0;
            left: 0;
        }
        &:after {
            top: auto;
            left: auto;
            bottom: 0;
            right: 0;
        } */
        &:before {
            content: '';
            background-color: #000;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 0.3;
            transition: 300ms;
            z-index: 1;
        }
        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border: 10px solid #e40001;
            transition: 400ms;
            transform: scale(0);
            opacity: 0;
        }
        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center center;
            transition: 400ms;
        }
        span {
            display: block;
            font-family: $medium;
            font-size: 18px;
            text-transform: uppercase;
            text-align: center;
            color: #fff;
            transition: 300ms;
            opacity: 0.8;
            position: absolute;
            top: 50%;
            left: 25px;
            right: 25px;
            transform: translateY(-50%);
            z-index: 1;
        }
        &:hover {
            text-decoration: none;
           /*  &:before, &:after {
               width: 100%;
               height: 100%;
           }
           &:before {
               border-top-color: #c74a34; 
               border-right-color: #c74a34;
               transition:
                   width 0.25s ease-out, 
                   height 0.25s ease-out 0.25s; 
           }
           &:after {
               border-bottom-color: #c74a34; 
               border-left-color: #c74a34;
               transition:
                   border-color 0s ease-out 0.5s, 
                   width 0.25s ease-out 0.5s, 
                   height 0.25s ease-out 0.75s;
           } */
            &:before {
                opacity: 0.1;
            }
            &:after {
                transform: scale(1);
                opacity: 1;
            }
            img {
                transform: scale(1.1);
            }
            span {
                opacity: 1;
            }
        }
    }
    .cat__slider {
        border-top: 3px solid #e40001;
        .slick-slide {
            outline: none;
            .cat__col {
                display: block;
                width: 100%;
            }
        }
        .slick-arrow {
            height: 30px;
            top: auto;
            bottom: -47px;
            transform: translate(0,0);
            z-index: 1;
            &:before {
                display: none;
            }
            &:after {
                content: '\f104';
                font-family: 'fontAwesome';
                font-size: 30px;
                color: #fff;
                transition: 300ms;
                line-height: 30px;
            }
            &.slick-prev {
                left: auto;
                right: 80px;
            }
            &.slick-next {
                right: 40px;
                &:after {
                    content: '\f105';
                }
            }
            &:hover {
                &:after {
                    color: #e40001;
                }
            }
        }
    }
    .cat__title {
        background-color: #111111;
        padding: 27px 40px;
        box-sizing: border-box;
        line-height: 1;
        a {
            display: table;
            font-family: $medium;
            font-size: 16px;
            color: #e40001;
            text-transform: uppercase;
            position: relative;
            &:after {
                content: '';
                width: 37px;
                height: 2px;
                background-color: #e40001;
                position: absolute;
                left: 0;
                bottom: -10px;
            }
            &:hover {
                text-decoration: none;
                color: #fff;
            }
        }
    }
    @media screen and (max-width: 1199px) {
        .cat__col {
            height: 280px;
        }
    }
    @media screen and (max-width: 991px) {
        .cat__col {
            height: 220px;
            span {
                font-size: 16px;
            }
        }
    }
    @media screen and (max-width: 767px) {
        .cat__slider {
            .cat__col {
                height: 280px;
            }
            .slick-arrow {
                bottom: -43px;
                &.slick-next {
                    right: 20px;
                }
                &.slick-prev {
                    right: 50px;
                }
            }
        }
        .cat__title {
            padding: 20px 20px 25px;
        }
    }
    @media screen and (max-width: 520px) {
        .cat__slider {
            .cat__col {
                height: 240px;
            }
        }
        .cat__col {
            span {
                font-size: 14px;
            }
        }
    }
}
/* main cat */

/* main about */
.about {
    position: relative;
    overflow: hidden;
    color: #fff;
    &:after {
        content: '';
        background-color: rgba(0,0,0,0.5);
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
    }
    > img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center center;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
    }
    h1 {
        text-transform: inherit;
    }
    .container {
        padding-bottom: 0;
        z-index: 1;
    }
    .about__block {
        padding: 50px 30px;
        margin-left: 10px;
        position: relative;
        &:before, &:after {
            content: '';
            width: 40px;
            height: 40px;
            position: absolute;
        }
        &:before {
            left: 0;
            top: 0;
            border-top: 2px solid #e40001;
            border-left: 2px solid #e40001;
        }
        &:after {
            right: 0;
            bottom: 0;
            border-right: 2px solid #e40001;
            border-bottom: 2px solid #e40001;
        }
    }
    @media screen and (max-width: 1199px) {
        .about__block {
            padding: 20px 20px;
        }
    }
    @media screen and (max-width: 767px) {
        .about__block {
            margin: 25px 0 0 0;
        }
    }
}
/* main about */

/* main advantages */
.advantages {
    background-color: #e40001;
    font-size: 0;
    margin-top: 80px;
    .advantages__col {
        @include inline-block;
        width: 33.33%;
        height: 230px;
        font-size: 16px;
        position: relative;
        &:nth-child(2) {
            background-color: #ca0202;
        }
        &:nth-child(3) {
            background-color: #ab0000;
        }
        .advantages__col__title {
            font-family: $medium;
            font-size: 20px;
            color: #fefefe;
            padding-left: 100px;
            position: absolute;
            top: 50%;
            left: 30px;
            right: 30px;
            transform: translateY(-50%);
            img {
                display: block;
                width: 70px;
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }
    @media screen and (max-width: 1199px) {
        margin-top: 40px;
        .advantages__col {
            height: 200px;
        }
    }
    @media screen and (max-width: 991px) {
        .advantages__col {
            height: 150px;
            .advantages__col__title {
                font-size: 18px;
                padding-left: 50px;
                left: 15px;
                right: 15px;
                img {
                    width: 40px;
                }
            }
        }
    }
    @media screen and (max-width: 767px) {
        .advantages__col {
            height: 125px;
            .advantages__col__title {
                font-size: 16px;
                right: 10px;
            }
        }
    }
    @media screen and (max-width: 620px) {
        .advantages__col {
            height: 100px;
            width: 100%;
        }
    }
}
/* main advantages */

/* main news */
.news {
    position: relative;
    overflow: hidden;
    &:after {
        content: '';
        width: 100%;
        background-color: #000;
        position: absolute;
        top: 80px;
        left: 50%;
        margin-left: -75px;
        bottom: 80px;
        opacity: 0.7;
        z-index: -1;
    }
    > img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center center;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
    }
    h2 {
        color: #fff;
        a {
            color: #fff!important;
            &:hover {
                color: #e40001!important;
            }
        }
    }
    .news__block {
        @include inline-block;
        width: 600px;
        float: right;
        margin: 45px 0;
        .news__block__col {
            display: block;
            min-height: 200px;
            position: relative;
            background-color: #ffffff;
            color: #717171;
            text-decoration: none;
            padding-left: 200px;
            margin-bottom: 20px;
            .img {
                height: 100%;
                width: 200px;
                position: absolute;
                left: 0;
                top: 0;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center center;
                }
                .date {
                    display: block;
                    background-color: #e40001;
                    font-family: $medium;
                    font-size: 16px;
                    color: #fefefe;
                    position: absolute;
                    top: 0;
                    left: 0;
                    padding: 7px 12px;
                    line-height: 1;
                }
            }
            .right {
                padding: 30px 20px; 
                box-sizing: border-box;
                h5 {
                    min-height: 52px;
                    font-family: $medium;
                    font-size: 16px;
                    text-transform: uppercase;
                    color: #393939;
                    margin: 0 0 10px;
                    transition: 300ms;
                }
                p {
                    margin: 0;
                }
                .red {
                    color: #e40001;
                }
            }
            &:hover {
                .right h5 {
                    color: #e40001;
                }
            }
        }
    }
    .all {
        display: table;
        font-family: $medium;
        font-size: 16px;
        color: #e40001;
        text-transform: uppercase;
        position: relative;
        margin-top: 10px;
        &:after {
            content: '';
            width: 37px;
            height: 2px;
            background-color: #e40001;
            position: absolute;
            left: 0;
            bottom: -10px;
        }
        &:hover {
            text-decoration: none;
            color: #fff;
        }
    }
    @media screen and (max-width: 1199px) {
        &:after {
            margin-left: -170px;
            top: 60px;
            bottom: 60px;
        }
    }
    @media screen and (max-width: 991px) {
        &:after {
            margin-left: -270px;
            top: 50px;
            bottom: 40px;
        }
    }
    @media screen and (max-width: 767px) {
        &:after {
            margin: 0;
            top: 0;
            left: 0;
            right: 0;   
            bottom: 0;
            opacity: 0.5;
        }
        .news__block {
            width: 100%;
            float: none;
            margin: 25px 0;
            .news__block__col {
                .right {
                    padding: 20px 15px;
                    h5 {
                        min-height: 0px;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 480px) {
        .news__block {
            .news__block__col {
                padding: 0;
                .img {
                    position: relative;
                    height: auto;
                    width: 100%;
                }
            }
        }
    }
}
/* main news */

/* main content */