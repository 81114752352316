/* page content */
.page {
    a {
        text-decoration: underline;
        &:hover {
            text-decoration: none;
        }
    }
	.news1, .news {
		.date {
			color: #e40001;
		}
		a {
			text-decoration: underline;
			&:hover {
				color: #413899;
				text-decoration: none;
			}
		}
	}
	.page_nav {
		.active {
			color: #413899;
		}
	}
    h1 {
        margin: 50px 0 30px;
    }
    h2 a {
        font-size: 16px;
    }
    .news--h1 {
        font-size: 24px;
    }
    #ask-form {
        max-width: 350px;
    }
	@media screen and (max-width: 1170px) {
		font-size: 16px;
		#photos-list td img {
			width: 100%;
			height: auto;
		}
	}
    @media screen and (max-width: 991px) {
        h1 {
            margin: 30px 0 15px;
        }
        .news--h1 {
            font-size: 28px;
        }
    }
    @media screen and (max-width: 767px) {
        .news--h1 {
            font-size: 24px;
        }
    }
	@media screen and (max-width: 480px) {
        .news--h1 {
            font-size: 22px;
        }
		p img, p strong img {
			width: 100%!important;
			height: auto!important;
			display: block!important;
			float: none!important;
			margin: 0 0 10px 0!important;		
		}
		#photos-list td {
			width: 50%;
			@include inline-block;
		}
	}
}
/* breadcrumbs */
.breadcrumbs {
	font-size: 14px;
    line-height: 1;
    margin-bottom: 40px;
	ul {
		@include ul-default;
		li {
			padding: 10px 12px;
            position: relative;
            font-family: $medium;
			color: #e40001;
            margin: 0 0 5px 10px;
			@include inline-block;
			a {
				text-decoration: none;
                font-size: 14px;
                color: #c6c6c6;
                &:hover {
                    color: #e40001;
                }
			}
			&:before {
				content: '';
                border-top: 3px solid transparent;
                border-bottom: 3px solid transparent;
                border-left: 5px solid #e40001;
                position: absolute;
                left: -5px;
                top: 13px;
				@include inline-block;
			}
			&:last-of-type {
                background-color: rgba(0,0,0,0.3);
                &:before {
                    display: none;
                }
            }
		}
	}
    @media screen and (max-width: 1199px) {
        margin-bottom: 50px;
    }
    @media screen and (max-width: 991px) {
        margin-bottom: 35px;
        ul li {
            padding: 7px 8px;
            &:before {
                top: 9px;
            }
        }
    }
    @media screen and (max-width: 767px) {
        margin-bottom: 25px;
    }
}
/* breadcrumbs */

.page__block {
    position: relative;
    > img {
        display: block;
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
        object-position: top center;
        z-index: -1;
    }
    > .container {
        width: 1280px;
        position: relative;
        background-color: #fff;
        padding: 70px;
        top: -355px;
        margin-bottom: -355px;
    }
    h2 {
        font-size: 42px;
    }
    /* page about */
    .about {
        color: #454545;
        &:after {
            display: none;
        }
        .about__brend {
            width: 100%;
            margin: 30px 0;
            tr {
                td {
                    height: 100px;
                    padding: 10px 10px;
                    border: 1px solid #e1e1e1;
                    box-sizing: border-box;
                    img {
                        display: block;
                        max-width: 100%;
                        margin: 0 auto;
                    }
                }
            }
        }
        .about__list {
            text-align: justify;
            text-align-last: justify;
            .about__list__col {
                max-width: 280px;
                @include inline-block;
                ul {
                    @include ul-default;
                    li {
                        display: table;
                        height: 60px;
                        width: 100%;
                        background-color: #f1f1f1;
                        text-align: center;
                        text-align-last: center;
                        text-align: center;
                        padding: 5px 20px;
                        margin: 30px 0;
                        border-left: 4px solid #e40001;
                        span {
                            display: table-cell;
                            width: 100%;
                            height: 100%;
                            vertical-align: middle;
                        }
                    }
                }
            }
        }
    }
    .box {
        display: table;
        background: url(../img/img-51.jpg) repeat top left;
        padding: 25px 25px 20px;
        border: 1px solid #e40001;
        color: #fff;
        margin: 25px 0;
    }
    /* page about */

    /* page catalog */
    .cat {
        border-top: 3px solid #e40001;
    }
    .cat--inside {
        font-size: 0;
        .cat--inside__col {
            @include inline-block;
            width: calc(25% - 20px);
            text-decoration: none;
            margin: 0 10px 15px;
            box-sizing: border-box;
            .cat--inside__col__img {
                height: 230px;
                overflow: hidden;
                border: 1px solid #e1e1e1;
                padding: 25px;
                box-sizing: border-box;
                transition: 300ms;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    object-position: center center;
                }
            }
            h5 {
                font-family: $bold;
                text-transform: uppercase;
                text-align: center;
                color: #414141;
                margin: 20px 0;
                transition: 300ms;
            }
            &:hover {
                .cat--inside__col__img {
                    box-shadow: 0px 0px 15px rgba(0,0,0,0.3);
                }
                h5 {
                    color: #e40001;
                }
            }
        }
    }
    .sidebar__btn {
        display: none;
    }
    .sidebar {
        background-color: #f1f1f1;
        .sidebar__title {
            display: block;
            font-family: $bold;
            font-size: 18px;
            color: #414141;
            padding: 18px 38px;
            cursor: pointer;
            position: relative;
            border-top: 1px solid #fff;
            border-bottom: 1px solid #fff;
            transition: 300ms;
            line-height: 1;
            &:after {
                content: '\f106';
                font-family: 'fontAwesome';
                font-size: 20px;
                color: #414141;
                position: absolute;
                top: 18px;
                left: 10px;
                line-height: 15px;
                transition: 300ms;
            }
            &.open {
                background-color: #e4e3e3;
                &:after {
                    transform: rotate(-180deg);
                }
            }
            &:hover {
                background-color: #e4e3e3;
            }
        }
        .sidebar__block {
            display: none;
            padding: 20px 10px;
        }
        .sidebar__close {
            display: none;
        }
    }
    input[type="radio"],
    input[type="checkbox"] {
        display: none;
    }
    .check {
        display: table;
        font-weight: normal;
        font-size: 16px;
        color: #414141;
        cursor: pointer;
        user-select: none;
        padding-left: 20px;
        margin: 6px 0;
        position: relative;
        transition: 300ms;
        &:before {
            content: '';
            width: 10px;
            height: 10px;
            border: 1px solid #dddddd;
            background-color: #fff;
            position: absolute;
            top: 6px;
            left: 0;
        }
        &:after {
            content: '';
            width: 6px;
            height: 6px;
            background-color: #e40001;
            position: absolute;
            left: 2px;
            top: 8px;
            transition: 300ms;
            opacity: 0;
        }
        &:hover {
            color: #e40001;
        }
    }
    .checkbox {
        font-weight: normal;
        color: #414141;
        user-select: none;
        position: relative;
        padding-left: 30px;
        cursor: pointer;
        &:before {
            content: '';
            width: 20px;
            height: 20px;
            background-color: #fff;
            border: 1px solid #dddddd;
            position: absolute;
            left: 0;
            top: 0;
        }
        &:after {
            content: '';
            background-color: #e40001;
            width: 10px;
            height: 10px;
            position: absolute;
            left: 5px;
            top: 5px;
            transition: 300ms;
            opacity: 0;
        }
    }
    input[type="radio"]:checked + label:after,
    input[type="checkbox"]:checked + label:after {
        opacity: 1;
    }
    input[type="radio"]:checked + .checkbox:before,
    input[type="checkbox"]:checked + .checkbox:before {
        border: 2px solid #e40001;
    } 

    .sidebar__block__input {
        @include inline-block;
        width: 45%;
        input {
            min-height: auto;
            font-size: 16px;
            background-color: #ffffff;
            padding: 4px 14px;
            border: 1px solid #dddddd;
            line-height: 1;
        }
        &.right {
            float: right;
        }
    }
    .range {
        @include inline-block;
        width: 7%;
        text-align: center;
        margin-top: 2px;
    }
    .ui-widget.ui-widget-content {
        height: 4px;
        border-radius: 0px;
        border: none;
        margin-left: 18px;
        background-color: #dddddd;
        position: relative;
        &:after {
            content: '';
            width: 18px;
            height: 4px;
            background-color: #dddddd;
            position: absolute;
            top: 0;
            left: -18px;
        }
        .ui-slider-range {
            background-color: #e40001;
            border-radius: 0px;
        }
        .ui-slider-handle {
            width: 18px;
            height: 18px;
            background-color: #fff;
            border-radius: 0px;
            margin-left: 0;
            box-sizing: border-box;
            border: 3px solid #e40001;
            top: -8px;
            margin-left: -18px;
            cursor: pointer;
            outline: none;
        }
    }

    .filter {
        text-align: right;
        margin-bottom: 15px;
        .dropdown {
            @include inline-block;
            width: auto;
            min-width: 190px;
            text-align: left;
            margin-right: 50px;
            border: none;
            box-shadow: none;
            &:after {
                content: '';
                width: 50px;
                height: 100%;
                background-image: url(../img/sort-button-with-three-lines.svg);
                background-repeat: no-repeat;
                background-size: 25px;
                background-position: center center;
                background-color: #393939;
                position: absolute;
                right: -50px;
                top: 0;
                transition: 300ms;
            }
            &:hover:after {
                background-color: #e40001;
            }
            .selected {
                background-color: #e4e3e3;
                font-family: $medium;
                font-size: 18px;
                color: #414141;
                padding: 15px 20px;
                box-shadow: none;
                &:after {
                    display: none;
                }
            }
            div {
                background-color: #f1f1f1;
                left: 0;
                right: 0;
                margin-top: 1px;
                border: none;
            }
            li {
                font-family: $regular;
                font-size: 16px;
                color: #393939;
                padding: 6px 20px;
                &.focus {
                    background-color: transparent;
                    color: #e40001;
                }
                &.active {
                    font-family: $medium;
                    background-color: transparent;
                }
                &:first-child {
                    padding-top: 15px;
                }
                &:last-child {
                    padding-bottom: 15px;
                }
            }
        }
    }
    .cat--category__col {
        font-size: 18px;
        text-decoration: none;
        position: relative;
        border: 1px solid #e1e1e1;
        margin-bottom: 35px;
        transition: 300ms;
        .stiker {
            top: 20px;
            left: -1px;
        }
        > a {
            display: block;
            padding: 20px;
            &:hover {
                text-decoration: none;
                color: #414141;
            }
        }
        h4 {
            min-height: 44px;
            font-family: $bold;
            margin: 10px 0 5px;
        }
        p {
            min-height: 50px;
        }
        .cat--category__col__img {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 160px;
            padding: 5px 25px;
            img {
                max-height: 100%;
                max-width: 100%;
                object-fit: contain;
                object-position: center center;
            }
        }
        .cat--category__col__down {
            font-family: $medium;
            font-size: 18px;
            text-align: center;
            padding: 12px 75px 13px 15px;
            border-top: 1px solid #e1e1e1;
            position: relative;
            .cart {
                width: 60px;
                background: #fff url(../img/cart.png) no-repeat center center;
                background-size: 25px;
                position: absolute;
                top: -1px;
                right: -1px;
                bottom: -1px;
                border: 1px solid #e40001;
                &:hover {
                    background: #e40001 url(../img/cart1.png) no-repeat center center;
                    background-size: 25px;
                }
            }
        }
        &:hover {
            box-shadow: 0px 0px 15px rgba(0,0,0,0.2);
        }
    } 
    .stiker {
        font-family: $bold;
        font-size: 14px;
        background-color: #2cc52c;
        color: #fff!important;
        text-transform: uppercase;
        padding: 9px 18px;
        position: absolute;
        left: 0;
        top: 0;
        line-height: 1;
        z-index: 1;
        &.red {
            background-color: #e40001;;
        }
        &.yellow {
            background-color: #d6a224;
        }
        @media screen and (max-width: 480px) {
            padding: 5px 12px;
        }
    }
    .red {
        color: #e40001;;
    }
    .blue {
        color: #2445ae;
    }
    .green {
        color: #5eac40;
    }
    .yellow {
        color: #d6a224;
    }
    .lime {
        color: #2cc52c;
    }
    
    .pagination {
        > a {
            @include inline-block;
            font-family: $bold;
            font-size: 14px;
            text-transform: uppercase;
            text-decoration: none;
            position: relative;
            line-height: 14px;
            &:after {
                content: '\f104';
                font-family: 'fontAwesome';
                font-size: 18px;
                color: #414141;
                position: absolute;
                top: 0px;
                transition: 300ms;
                line-height: 12px;
                vertical-align: middle;
            }
            &.prev {
                padding-left: 17px;
                margin-right: 30px;  
                &:after {
                    left: 0;
                }
            }
            &.next {
                padding-right: 17px;
                &:after {
                    content: '\f105';
                    right: 0;
                }
            }
            &:hover {
                &.prev:after {
                    left: -5px;
                }
                &.next:after {
                    right: -5px;
                }
            }
        }
        ul {
            @include ul-default;
            margin: 15px 0 0 0;
            li {
                @include inline-block;
                a {
                    display: block;
                    font-family: $bold;
                    padding: 10px 5px;
                    border-bottom: 2px solid transparent; 
                    &:hover {
                        text-decoration: none;
                        border-bottom: 2px solid #e40001;
                    }
                }
                &.active a {
                    color: #e40001;
                    border-bottom: 2px solid #e40001;
                }
            }
        }
    }

    .cat--unit {
        .col-md-5 {
            position: relative;
        }
        .cat--unit__for {
            position: relative;
            padding: 20px 0 30px;
            &:after {
                content: '\f00e';
                font-family: 'fontAwesome';
                font-size: 22px;
                color: #e40001;
                position: absolute;
                right: 0;
                bottom: 10px;
            }
            .slick-slide {
                height: 400px;
                outline: none;
                img {
                    display: block;
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    object-position: center center;
                }
            }
        }
        .cat--unit__nav {
            .slick-slide {
                height: 150px;
                overflow: hidden;
                position: relative;
                outline: none;
                cursor: pointer;
                padding: 10px;
                margin: 0 2px;
                &:after {
                    content: '';
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    background-color: rgba(255,255,255,0.5);
                    border: 2px solid #e40001;
                    opacity: 0;
                    transition: 300ms;
                }
                img {
                    height: 100%;
                    width: 100%;
                    object-fit: contain;
                    object-position: center center;
                }
                &:hover {
                    &:after {
                        opacity: 1;
                    }
                }
                &.slick-current:after {
                    opacity: 1;
                }
            }
            .slick-arrow {
                height: 30px;
                z-index: 1;
                &:before {
                    display: none;
                }
                &:after {
                    content: '\f104';
                    font-family: 'fontAwesome';
                    font-size: 30px;
                    color: #e40001;
                    transition: 300ms;
                    line-height: 30px;
                }
                &.slick-prev {
                    left: 10px;
                }
                &.slick-next {
                    right: 10px;
                    &:after {
                        margin-top: 3px;
                        transform: rotate(-180deg);
                    }
                }
                &:hover {
                    &:after {
                        opacity: 0.7;
                    }
                }
            }
        }
        .cat--unit--status {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 50px;
        }
        .cat--unit__status {
            display: block;
            font-family: $bold;
            font-size: 22px;
        }
        h2 {
            font-family: $bold;
            font-size: 30px;
        }
        h4 {
            font-family: $bold;
            margin: 25px 0 15px;
        }
        .cat--unit__block {
            background-color: #f1f1f1;
            border-bottom: 1px solid #fff;
            padding: 20px 30px;
            &.down {
                background-color: #e4e3e3;
            }
        }
        table {
            font-size: 16px;
            line-height: 1.2;
            strong {
                color: #757575;
                font-weight: normal;
                padding: 5px 5px 5px 0;
                background-color: #f1f1f1;
                position: relative;
                z-index: 1;
            }
            tr {
                td:nth-of-type(1) {
                    position: relative;
                    &:before {
                        content: '';
                        width: 100%;
                        position: absolute;
                        bottom: 9px;
                        left: 0;
                        border-bottom: 1px dashed #757575;
                    }
                }
            }
            td {
                min-width: 200px;
                padding: 5px 10px 5px 0;
            }
        }
        .add {
            display: table;
            font-family: $medium;
            font-size: 18px;
            color: #e40001;
            text-decoration: none;
            padding: 15px 85px 15px 25px;
            border: 1px solid #e40001;
            position: relative;
            margin: 0;
            &:after {
                content: '';
                width: 60px;
                background: #e40001 url(../img/cart1.png) no-repeat center center;
                background-size: 25px;
                position: absolute;
                top: -1px;
                right: -1px;
                bottom: -1px;
                border: 1px solid #e40001;
                transition: 300ms;
            }
            &:hover {
                &:after {
                    background: #fff url(../img/cart.png) no-repeat center center;
                    background-size: 25px;   
                }
            }
        }
        @media screen and (max-width: 1024px) {
            h2 {
                font-size: 24px;
            }
            .cat--unit--status {
                margin-bottom: 30px;
            }
        }
        @media screen and (max-width: 768px) {
            h2 {
                font-size: 22px;
            }
        }
        @media screen and (max-width: 480px) {
            .cat--unit--status {
                flex-wrap: wrap;
                justify-content: flex-start;
                .cat--unit__status {
                    width: 100%;
                }
                .add {
                    margin-top: 15px;
                }
            }
        }
    }
    .tabs {
        border-bottom: 3px solid #e40001;
        margin: 35px 0;
        ul {
            @include ul-default;
            li {
                @include inline-block;
                a {
                    display: block;
                    font-family: $medium;
                    color: #8e8e8e;
                    text-transform: uppercase;
                    position: relative;
                    padding: 20px 45px;
                    &:after {
                        content: '';
                        width: 15px;
                        height: 15px;
                        background: #fff;
                        border: 3px solid #e40001;
                        position: absolute;
                        left: 0;
                        right: 0;
                        bottom: -9px;
                        margin: 0 auto;
                        opacity: 0;
                        transition: 300ms;
                    }
                    &:hover {
                        color: #e40001;
                        text-decoration: none;
                        &:after {
                            opacity: 1;
                        }
                    }
                }
                &.active a {
                    color: #e40001;
                    &:after {
                        opacity: 1;
                    }
                }
            }
        }
    }
    /* page catalog */

    /* page cabinet */
    .cabinet {
        form {
            display: block;
            h3 {
                margin-bottom: 25px;
            }
            span, h5 {
                font-size: 18px;
                color: #787878;
                display: block;
                margin-bottom: 10px;
                sup {
                    font-size: 18px;
                    color: #cb5642;
                    line-height: 1;
                    top: 0;
                }
            }
            h5 {
                font-family: $medium;
                color: #414141;
                margin: 0;
            }
            .cabinet__checkbox {
                margin: 30px 0;
                h5 {
                    @include inline-block;
                    min-width: 220px;
                }
                .right {
                    @include inline-block;
                    .checkbox {
                        @include inline-block;
                        margin: 0 45px 15px 0;
                    }
                    .col {
                        @include inline-block;
                        width: 49%;
                        padding-right: 10px;
                        box-sizing: border-box;
                    }
                }
                &.second {
                    .right {
                        width: 700px;
                        margin-right: 0;
                        .checkbox {
                            display: table;
                            margin-right: 0;
                        }
                    }
                }
            }
        }
        .file-upload {
            input[type="file"]{
                display: none;
            }
            span {
                font-weight: normal;
                color: #414141;
                text-decoration: underline;
                padding-left: 28px;
                position: relative;
                cursor: pointer;
                margin: 0;
                &:after {
                    content: '\f0c6';
                    font-family: 'fontAwesome';
                    font-size: 20px;
                    color: #414141;
                    position: absolute;
                    left: 0;
                    top: 1px;
                }
                &:hover {
                    color: #e40001;
                    text-decoration: none;
                }
            }
        }
        .g-recaptcha {
            @include inline-block;
            margin: 45px 70px 0 70px;
        }
        .btn {
            @include inline-block;
            margin: 45px 0 0 0;
        }
        &.user {
            form {
                width: 50%;
            }
            .user__btn {
                text-align: right;
                
            }
        }
    }

    .history {
        width: 100%;
        font-size: 18px;
        tr {
            th {
                background-color: #393939;
                font-family: $medium;
                font-weight: normal;
                font-size: 14px;
                color: #fff;
                text-transform: uppercase;
                padding: 14px 15px;
            }
            td {
                padding: 25px 15px;
                p {
                    margin: 0;
                }
            }
            &.history__head {
                th.history__head__name {
                    padding-left: 220px;
                }
            }
            &.history__body {
                border-bottom: 1px solid #e1e1e1;
                td {
                    &.history__body__name {
                        width: 45%;
                        a {
                            display: table;
                            .history--img, .history__title{
                                display: table-cell;
                                vertical-align: middle;
                            }
                            .history--img {
                                width: 210px;
                                height: 140px;
                                img {
                                    width: 100%;
                                    height: 100%;
                                    object-fit: contain;
                                    object-position: center center;
                                }
                            }
                            .history__title {
                                padding-left: 10px;
                            }
                        }
                    }
                } 
            }
            &.history__total { 
                background-color: #e1e1e1;
            }
        }
    }
    /* page cabinet */

    /* page basket */
    .basket{
        width: 100%;
        font-size: 18px;
        margin-bottom: 50px;
        tr {
            th {
                background-color: #393939;
                font-family: $medium;
                font-weight: normal;
                font-size: 14px;
                color: #fff;
                text-transform: uppercase;
                padding: 14px 15px;
            }
            td {
                padding: 25px 15px;
                p {
                    margin: 0;
                }
                .close {
                    display: block;
                    width: 25px;
                    height: 25px;
                    background: url(../img/cancelG.svg) no-repeat center center;
                    background-size: contain;
                    opacity: 1;
                    &:hover {
                        background: url(../img/cancelR.svg) no-repeat center center;
                        background-size: contain;
                    }
                }
                .quantity {
                    width: 70px;
                    height: 50px;
                    position: relative;
                    border: 1px solid #e1e1e1;
                    margin: 0 auto;
                    transition: 300ms;
                    input {
                        width: 40px;
                        background-color: transparent;
                        text-align: center;
                        padding: 14px 5px;
                        margin: 0;
                    }
                    .minus, .plus {
                        width: 10px;
                        height: 10px;
                        position: absolute;
                        right: 10px;
                        outline: none;
                        &:after {
                            content: '';
                            width: 0;
                            height: 0;
                            position: absolute;
                            left: 0;
                            right: 0;
                            top: 50%;
                            border-left: 4px solid transparent;
                            border-right: 4px solid transparent;
                            margin: -3px auto;
                            transition: 300ms;
                        }
                    }
                    .plus {
                        top: 13px;
                        &:after {
                            border-bottom: 6px solid #414141;
                        }
                        &:hover {
                            &:after {
                                border-bottom: 6px solid #e40001;
                            }
                        }
                    }
                    .minus {
                        bottom: 13px;
                        &:after {
                            border-top: 6px solid #414141;
                        }
                        &:hover {
                            &:after {
                                border-top: 6px solid #e40001;
                            }
                        }
                    }
                    &:hover {
                        border: 1px solid #e40001;
                    }
                }
            }
            &.basket__head {
                th {
                    &.basket__head__name {
                        width: 40%;
                        padding-left: 180px;
                    }
                    &.basket__head__quantity {
                        width: 10%;
                        text-align: right;
                    }
                }

            }
            &.basket__body {
                border-bottom: 1px solid #e1e1e1;
                td {
                    &.basket__body__name {
                        a {
                            display: table;
                            .basket--img, h5 {
                                display: table-cell;
                                vertical-align: middle;
                                font-family: $medium;
                            }
                            .basket--img {
                                width: 170px;
                                height: 140px;
                                img {
                                    width: 100%;
                                    height: 100%;
                                    object-fit: contain;
                                    object-position: center center;
                                }
                            }
                            h5 {
                                padding-left: 10px;
                            }
                            &:hover {
                                h5 {
                                    color: #e40001;
                                }
                            }
                        }
                    }
                } 
            }
            &.basket__total { 
                background-color: #e1e1e1;
                td {
                    padding: 15px 25px;
                    a {
                        font-family: $bold;
                        font-size: 18px;
                        color: #414141;
                        text-decoration: underline;
                        &:hover {
                            text-decoration: none;
                            color: #e40001;
                        }
                        &.next {
                            color: #e40001;
                            float: right;
                        }
                    }
                }
            }
        }
    }
    .warning {
        background-color: #d88071;
        font-family: $italic;
        font-size: 18px;
        color: #fff;
        padding: 25px 30px;
        margin: 25px 0;
        p {
            margin: 5px 0;
        }
    }
    .formalization {
        padding-top: 25px;
        h3 {
            text-align: center;
            margin-bottom: 35px;
        }
        span {
            display: block;
            font-size: 18px;
            color: #787878;
            margin-bottom: 10px;
            sup {
                font-size: 18px;
                color: #cb5642;
                line-height: 1;
                top: 0;
            }
        }
        .dropdown {
            width: 100%;
            border: none;
            box-shadow: none!important;
            .selected {
                min-height: 48px;
                font-family: $regular;
                background-color: #f1f1f1;
                color: #787878;
                padding: 14px 20px;
                margin-bottom: 20px;
                boder: none;
                outline: none;
                &:after {
                    display: none;
                }
            }
            .carat {
                height: 20px;
                font-family: 'fontAwesome';
                font-size: 20px;
                right: 20px;
                margin-top: -10px;
                &:after {
                    content: '\f107';
                }
            }
            > div {
                left: 0;
                right: 0;
                margin-top: 1px;
                border: 1px solid #dedede;
                background-color: #f1f1f1;
            }
            ul li {
                font-size: 16px;
                padding: 8px 20px;
                &.focus, &.active {
                    background-color: transparent;
                    color: #e40001;
                }
            }
        }
        .g-recaptcha {
            @include inline-block;
            margin: 45px 70px 0px 100px;
        }
        .btn {
            @include inline-block;
            margin-top: 45px;
        }
    }
    /* page basket */

    /* page team */
    .team {
        display: flex;
        flex-wrap: wrap;
        .team__col {
            display: flex;
            flex-direction: column;
            width: 25%;
            padding: 0 15px;
            margin-bottom: 15px;
            line-height: 1;
            .team__col__img {
                height: 300px;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center center;
                }
            }
            .team__col--info {
                flex: 1 0 auto;
                background-color: #f1f1f1;
                border-left: 4px solid #e40001;
                padding: 28px 20px 28px 30px;
                margin-top: 4px;

            }
            h5 {
                font-family: $medium;
                color: #454545;
                margin: 0 0 13px;
            }
            .pos {
                display: block;
                font-size: 14px;
                color: #8a8a8a;
                margin-bottom: 20px;
            }
            ul {
                @include ul-default;
                li {
                    margin: 5px 0;
                }
            }
        }
    
    }
    /* page team */

    /* page news */
    .news {
        &:after {
            display: none;
        }
        .news__block {
            width: 100%;
            float: none;
            margin: 0;
            .news__block__col {
                @include inline-block;
                width: 49%;
                min-height: 226px;
                .img {
                    height: 226px;
                }
            }
        }
    }
    /* page news */

    /* page distribution */
    .distr {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -15px;
        .distr__item {
            width: calc(25% - 30px);
            height: 150px;
            display: flex;
            background-color: #fff;
            align-items: center;
            justify-content: center;
            padding: 25px;
            margin: 0 15px 30px;
            position: relative;
            &:after {
                content: '';
                pointer-events: none;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                top: 0;
                border: 1px solid #e8e8e8;
                transition: 300ms;
            }
            img {
                max-width: 100%;
                max-height: 100%;
                object-fit: contain;
                object-position: center center;
            }
            span {
                display: block;
                font-size: 14px;
                text-align: center;
                background-color: #7f7f7f;
                color: #fff;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                padding: 7px 10px;
                transition: 300ms;
                opacity: 0;
            }
            &:hover {
                &:after {
                    border: 2px solid #e40001;
                }
                span {
                    opacity: 1;
                }
            }
        }
        @media screen and (max-width: 768px) {
            margin: 0 -10px;
            .distr__item {
                width: calc(33.33% - 20px);
                margin: 0 10px 20px;
            }
        }
        @media screen and (max-width: 600px) {
            .distr__item {
                height: 120px;
            }
        }
        @media screen and (max-width: 480px) {
            .distr__item {
                width: calc(50% - 20px);
                padding: 15px;
                span {
                    display: none;
                }
            }
        }
    }

    .distr--inside {
        display: flex;
        align-items: flex-start;
        > .left {
            width: 265px;
            img {
                display: block;
                max-width: 100%;
            }
        }
        > .right {
            width: calc(100% - 265px);
            padding-left: 30px;
        }
        a {
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
        }
        .distr--inside__nav {
            @include ul-default;
            li {
                margin-top: 1px;
                a {
                    display: flex;
                    min-height: 60px;
                    background-color: #f1f1f1;
                    font-family: $medium;
                    font-size: 18px;
                    align-items: center;
                    padding: 10px 15px;
                    line-height: 1;
                    img {
                        width: 16px;
                        height: 16px;
                        object-fit: contain;
                        object-position: center center;
                        margin-left: 12px;
                    }
                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }
        .distr--inside__title {
            width: 100%;
            font-family: $medium;
            background-color: #f1f1f1;
            padding: 15px 18px;
            margin: 30px 0 30px;
        }
        .distr--inside__price {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            ul {
                @include ul-default;
            }
            .distr--inside__col {
                width: calc(50% - 30px);
                &:first-of-type {
                    ul li:last-of-type {
                        border-bottom: none;
                    }
                }
                h6 {
                    font-family: $medium;
                    text-transform: uppercase;
                    margin: 0 0 16px;
                }
                ul {
                    li {
                        padding-bottom: 28px;
                        margin-bottom: 28px;
                        border-bottom: 1px dashed #b7b7b7;
                        > a {
                            font-family: $medium;
                            text-transform: uppercase;
                            color: #e40001;
                        }
                    }
                }
                .distr--inside__links {
                    padding-left: 15px;
                    a {
                        color: #e40001;
                    }
                }
            }
        }
        @media screen and (max-width: 1024px) {
            flex-wrap: wrap;
            > .left {
                width: 100%;
                margin-bottom: 25px;
                a {
                    display: block;
                    margin-bottom: 10px;
                }

            }
            > .right {
                width: 100%;
                padding-left: 0;
            }
            .distr--inside__title {
                padding: 10px 15px;
                margin: 15px 0;
            }
            .distr--inside__price .distr--inside__col {
                ul {
                    li {
                        padding-bottom: 20px;
                        margin-bottom: 20px;
                    }
                }
                &:first-of-type {
                    ul li:last-of-type {
                        border-bottom: 1px dashed #b7b7b7;
                    }
                }
            }
        }
        @media screen and (max-width: 768px) {
            .distr--inside__nav li a {
                min-height: 55px;
                font-size: 16px;
            }
        }
        @media screen and (max-width: 480px) {
            > .left {
                img {
                    width: 100%;
                }
            }
            .distr--inside__price {
                justify-content: flex-start;
                .distr--inside__col {
                    width: 100%;
                }
            }
        }
    }
    .distr__form {
        background-color: #f1f1f1;
        padding: 70px 40px;
        margin-top: 20px;
        h2 {
            font-family: $medium;
            font-size: 36px;
            margin-bottom: 25px;
        }
        p {
            font-size: 18px;
            margin-bottom: 25px;
        }
        input[type="text"],
        input[type="email"],
        textarea {
            background-color: #fff;
        }
        .distr__form--inp {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            .distr__form--col {
                width: calc(50% - 10px);
            }
            p {
                font-size: 14px;
                margin-bottom: 15px;
            }
        }
        .file-upload {
            input[type="file"]{
                display: none;
            }
            span {
                font-family: $bold;
                font-weight: normal;
                color: #e40001;
                text-transform: uppercase;
                padding-left: 28px;
                position: relative;
                cursor: pointer;
                margin: 0;
                &:after {
                    content: '';
                    width: 17px;
                    height: 17px;
                    background: url(../img/icon-1.png) no-repeat;
                    position: absolute;
                    left: 0;
                    top: 1px;
                }
                &:hover {
                    color: #e40001;
                    text-decoration: none;
                }
            }
        }
        @media screen and (max-width: 1024px) {
            padding: 50px 20px;
            h2 {
                font-size: 28px;
            }
        }
        @media screen and (max-width: 768px) {
            padding: 30px 20px;
            h2 {
                font-size: 24px;
                margin-bottom: 15px;
            }
            p {
                font-size: 16px;
                margin-bottom: 20px;
            }
            .distr__form--inp {
                .distr__form--col {
                    width: 100%;
                }
            }
            .file-upload {
                font-size: 14px;
            }
            .btn {
                float: right;
                margin-top: -40px;
            }
        }
        @media screen and (max-width: 500px) {
            padding-bottom: 80px;
            .g-recaptcha {
                float: right;
            }
            .btn {
                margin-top: 15px;
            }
        }
        @media screen and (max-width: 380px) {
            .g-recaptcha {
                transform: scale(0.84);
                transform-origin: 100% 50%;
            }
        }
    }

    .distr--inside__block {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -15px;
        .cat--category__col {
            width: calc(33.33% - 30px);
            position: relative;
            padding-bottom: 50px;
            margin: 0 15px 30px;
            .art {
                display: block;
                font-size: 14px;
                color: #999999;
                text-align: center;
                margin: 10px 0;
            }
            .cat--category__col__down {
                display: flex;
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                height: 50px;
                padding-top: 0;
                padding-bottom: 0;
                padding-left: 0;
                padding-right: 65px;
                span {
                    display: flex;
                    width: 100%;
                    align-items: center;
                    justify-content: center;
                    height: 100%;
                }
                .art {
                    display: none;
                    border-right: 1px solid #e1e1e1;        
                    margin: 0;                 
                }
            }
        }
        &.distr--rebuild {
            margin: 0;
            .cat--category__col {
                width: 100%;
                padding-left: 195px;
                padding-bottom: 0;
                margin: 0 0 40px;
                > a {
                    padding-left: 0;
                }
                p {
                    min-height: 0;
                }
                .art {
                    display: none;
                }
                .cat--category__col__img {
                    width: 195px;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                }
                .cat--category__col__down {
                    position: relative;
                    .art {
                        display: flex;
                        width: 200px;
                        border-left: 1px solid #e1e1e1; 
                    }
                }
            }
        }
        @media screen and (max-width: 1024px) {
            margin: 0 -10px;
            .cat--category__col {
                width: calc(33.33% - 20px);
                margin: 0 10px 20px;
            }
        }
        @media screen and (max-width: 768px) {
            .cat--category__col {
                width: calc(50% - 20px);
            }
            &.distr--rebuild {
                .cat--category__col {
                    padding-left: 120px;
                    .cat--category__col__img {
                        width: 120px;
                        padding: 15px;
                    }
                }
            }
        }
        @media screen and (max-width: 480px) {
            margin: 0;
            .cat--category__col {
                width: 100%;
                margin: 0 0 20px;
                .stiker {
                    top: 10px;
                }
            }
            &.distr--rebuild {
                .cat--category__col {
                    padding-left: 100px;
                    .cat--category__col__img {
                        width: 100px;
                    }
                    .cat--category__col__down {
                        height: auto;
                        padding-right: 60px;
                        flex-wrap: wrap;    
                        align-items: flex-start;
                        border-left: 1px solid #e1e1e1;
                        span {
                            height: 35px;
                        }
                        .art {
                            height: 20px;
                            width: 100%;
                            border-left: none;
                            border-right: none;
                            margin: 5px 0 0;
                        }
                    }
                }
            }
        }
    }

    .distr__filter {
        display: flex;
        height: 50px;
        background-color: #f1f1f1;
        position: relative;
        padding-right: 140px;
        line-height: 1;
        margin: 30px 0;
        h5 {
            font-family: $medium;
            color: #414141;
            margin: 5px 10px 5px 30px;
        }
        .distr__filter--col {
            display: flex;
            width: 100%;
            align-items: center;
            form input[type="search"] {
                border: none;
                padding: 4px 10px;
            }
        }
        .dropdown {
            min-width: 230px;
            background-color: #e4e3e3;
            border: none;
            color: #414141;
            box-shadow: none;
            outline: none;
            .selected {
                font-size: 18px;
                padding: 15px 30px 15px 18px;
                &:after {
                    box-shadow: inset -55px 0 20px -10px #e4e3e3;
                }
            }
            div {
                border: none;
                left: 0;
                right: 0;
                margin-top: 1px;
            }
            ul {
                background-color: #e4e3e3;
            }
            li {
                padding: 5px 18px;
                &.focus, &.hover, &.active {
                    background-color: transparent;
                    color: #e40001;
                }
            }
            .carat {
                width: auto;
                height: auto;
                font-family: 'fontAwesome';
                font-size: 16px;
                color: #414141;
                background: none;
                &:after {
                    content: '\f107';
                    line-height: 8px;
                }
            }
            &.open .carat {
                margin-top: -2px;
            }
            li {
                font-size: 16px;
            }
        }
        .rebuild {
            display: flex;
            height: 50px;
            position: absolute;
            top: 0;
            right: 0;
            a {
                width: 50px;
                height: 100%;
                background-color: #393939;
                position: relative;
                span {
                    position: absolute;
                    background-color: #fff;
                }
                &.btn--f {
                    span {
                        height: 4px;
                        width: 26px;
                        left: 0;
                        right: 0;
                        top: 50%;
                        margin: auto;
                        transform: translateY(-1px);
                        &:nth-of-type(2) {
                            transform: translateY(-8px);
                        }
                        &:nth-of-type(3) {
                            transform: translateY(6px);
                        }
                    }
                }
                &.btn--s {
                    span {
                        width: 8px;
                        height: 8px;
                        top: 50%;
                        left: 0;
                        right: 0;
                        margin: auto;
                        &:nth-of-type(1) {
                            transform: translate(-5px, -8px);
                        }
                        &:nth-of-type(2) {
                            transform: translate(5px, -8px);
                        }
                        &:nth-of-type(3) {
                            transform: translate(-5px, 3px);
                        }
                        &:nth-of-type(4) {
                            transform: translate(5px, 3px);
                        }
                    }
                }
                &:hover, &.active  {
                    background-color: #e40001;
                }
            }
        }
        @media screen and (max-width: 1024px) {
            font-size: 16px;
            h5 {
                font-size: 16px;
                margin-left: 15px;
            }
            .distr__filter--col {
                width: auto;
            }
            .dropdown {
                min-width: 180px;
                .selected {
                    font-size: 16px;
                }
                li {
                    font-size: 14px;
                }
            }
        }
        @media screen and (max-width: 768px) {
            height: 40px;
            .dropdown {
                .selected {
                    padding: 11px 30px 11px 15px;
                }
            }
            .rebuild {
                height: 40px;
                a {
                    width: 40px;
                }
            }
        }
        @media screen and (max-width: 680px) {
            height: auto;
            padding-right: 40px;
            flex-wrap: wrap;
            h5 {
                min-width: 100px;
            }
            .distr__filter--col {
                width: 100%;
                form {
                    width: 180px;
                    margin: 5px 0;
                    input[type="search"] {
                        width: 100%;
                    }
                }
            }
            .rebuild {
                height: auto;
                flex-direction: column;
                a {
                    height: 38px;
                }
            }
        }
        @media screen and (max-width: 380px) {
            .dropdown {
                width: 150px;
                min-width: 0px;
            }
            .distr__filter--col {
                form {
                    width: 150px;
                }
            }
        }
        @media screen and (max-width: 350px) {
            .distr__filter--col {
                form {
                    width: calc(100% - 120px);
                }
            }
        }
    }
    /* page distribution */

    /* page contacts */
    .contacts {
        h3 {
            margin-bottom: 25px;
        }
        h5 {
            font-family: $medium;
            margin-bottom: 15px;
        }
        .contacts__list {
            @include ul-default;
            padding-right: 50px;
            li {
                position: relative;
                font-size: 18px;
                padding: 35px 0 35px 115px;
                border-bottom: 1px solid #e1e1e1;
                &:last-child {
                    border-bottom: none;    
                }
                img {
                    display: block;
                    width: 90px;
                    position: absolute;
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%);
                }
                a {
                    display: block;
                }
                &.email {
                    a {
                        @include inline-block;
                    }
                }
            }
        }
        form {
            p {
                font-size: 18px;
                font-family: $medium;
                line-height: 1.2;
                margin-bottom: 30px;
            }
            input[type="text"],
            input[type="email"] {
                width: calc(100% - 155px);
            }
            .g-recaptcha {
                @include inline-block;
                margin-top: 20px;
            }
            .btn {
                @include inline-block;
                margin-top: 20px;
                float: right;
            }
        }
    }
    .map {
        height: 600px;
        .ymaps-2-1-69-map {
            height: 100%!important;
        }
    }
    /* page contacts */

    @media screen and (max-width: 1440px) {
        > .container {
            width: 1200px;
            padding: 50px 40px;
        }
    }
    @media screen and (max-width: 1199px) {
        h2 {
            font-size: 36px;
        }
        > .container {
            width: 100%;
            padding: 25px 15px 40px;
            top: 0;
            margin-bottom: 0;
        }
        .news .news__block {
            .news__block__col {
                .right {
                    padding: 10px 15px;
                }
            }
        }
    }
    @media screen and (max-width: 991px) {
        h2 {
            font-size: 28px;
        }
        .about .about__list .about__list__col {
            width: 32%;
            ul li {
                padding: 5px 10px;
            }
        }
        .cat--inside {
            .cat--inside__col .cat--inside__col__img {
                height: 180px;
            }
        }
        .sidebar__btn {
            display: block;
            font-family: $bold;
            font-size: 18px;
            color: #fff;
            text-transform: uppercase;
            background-color: #e40001;
            padding: 14px 40px 14px 20px;
            cursor: pointer;
            position: relative;
            margin-bottom: 15px;
            line-height: 1;
            &:after {
                content: '\f0b0';
                font-family: 'fontAwesome';
                font-size: 25px;
                position: absolute;
                top: 10px;
                right: 10px;
            }
        }
        .sidebar {
            display: none;
            max-height: calc(100% - 95px);
            position: fixed;
            top: 94px;
            left: 0;
            right: 0;
            padding-bottom: 50px;
            overflow: auto;
            margin: 0;
            z-index: 2;
            .sidebar__close {
                display: block;
                background-color:#e40001;
                font-family: $bold;
                font-size: 18px;
                color: #fff;
                padding: 12px 20px;
                text-align: center;
                position: fixed;
                left: 0;
                right: 0;
                bottom: 0;
            }
        }
        .cat--unit {
            .stiker {
                left: 15px;
            }
            .cat--unit__for {
                .slick-slide {
                    height: 300px;
                }
            }
            .cat--unit__nav {
                margin-bottom: 25px;
            }
            .cat--unit__tabs {
                margin-top: 10px;
            }
        }
        .cabinet form .cabinet__checkbox {
            h5 {
                margin-bottom: 15px;
            }
            .right {
                .checkbox {
                    margin-right: 20px;
                }
            }
        }
        .history {
            font-size: 16px;
            tr {
                &.history__head {
                    th {
                        font-size: 12px;
                        &.history__head__name {
                            padding-left: 115px;
                        }
                    }
                }
                &.history__body {
                    td {
                        &.history__body__name {
                            width: 30%;
                            a {
                                .history--img {
                                    width: 100px;
                                    height: 100px;
                                }
                            }
                        }
                    }
                }
            }
        }
        .basket {
            font-size: 16px;
            tr {
                &.basket__head {
                    th {
                        font-size: 12px;
                        &.basket__head__name {
                            padding-left: 135px;
                        }
                    }
                }
                &.basket__body {
                    td {
                        &.basket__body__name a {
                            .basket--img {
                                width: 120px;
                                height: 100px;
                            }
                        }
                        h5 {
                            font-size: 16px;
                        }
                    }
                }
            }
        }
        .warning {
            font-size: 16px;
        }
        .team .team__col {
            width: 33.33%;
            .team__col__img {
                height: 220px;
            }
            h5 {
                font-size: 16px;
            }
        }
        .news .news__block {
            .news__block__col {
                padding-left: 120px;
                .img {
                    width: 120px;
                    height: 120px;
                }
            }
        }
        .contacts {
            .contacts__list li {
                font-size: 16px;
                padding: 15px 0 15px 100px;
                img {
                    width: 70px;
                }
            }
            form p {
                font-size: 16px;
            }
        }
        .map {
            height: 400px;
            > ymaps {
                height: 100%!important;
            }
        }
    }
    @media screen and (max-width: 767px) {
        h2 {
            font-size: 24px;
        }
        .box {
            padding: 15px 15px 10px;
        }
        .cat--inside {
            .cat--inside__col {
                .cat--inside__col__img {
                    height: 150px;
                }
                h5 {
                    font-size: 16px;
                }
            }
        }
        .cat--category__col {
            margin-bottom: 15px;
            .cat--category__col__img {
                padding: 0;
            }
            h4, p {
                min-height: 0px;
            }
        }
        .pagination {
            margin-bottom: 0;
            ul {
                margin-top: 0;
            }
        }
        .sidebar {
            max-height: calc(100% - 77px);
            top: 78px;
            .sidebar__title {
                padding: 15px 20px 15px 34px;
                &:after {
                    top: 15px;
                }
            }
            .sidebar__block {
                padding: 10px;
                #slider {
                    margin-bottom: 10px;
                }
            }
        }
        .tabs {
            ul li a {
                padding: 15px 25px; 
            }
        }
        .cat--unit {
            .cat--unit__nav {
                .slick-slide {
                    height: 120px;
                }
            }
            .cat--unit__tabs {
                li a {
                    padding: 15px 25px; 
                }
            }
            .add {
                padding: 12px 85px 12px 20px;
            }
        }
        .cabinet {
            form {
                span {
                    font-size: 16px;
                }
                h3 {
                    margin: 15px 0;
                }
                .cabinet__checkbox {
                    width: auto;
                    margin-bottom: 0px;
                    &.second .right {
                        width: auto;
                    }
                }
            }
            .g-recaptcha {
                margin: 25px 25px 15px 0;
            }
            .btn {
                margin-top: 25px;
            }
            &.user {
                form {
                    width: 100%;
                }
            }
        }
        .history {
            tr {
                position: relative;
                &.history__head {
                    display: none;
                }
                &.history__body {
                    display: block;
                    min-height: 140px;
                    padding: 10px 130px 10px 0px;
                    td {
                        padding: 10px;
                        &.history__body__name {
                            padding: 10px 0;
                        }
                        &.history__body__date,
                        &.history__body__delivery,
                        &.history__body__pay,
                        &.history__body__status {
                            display: block;
                            position: absolute;
                            right: 0;
                            padding: 0;
                        }
                        &.history__body__date {
                            top: 15px;
                        }
                        &.history__body__delivery {
                            top: 45px;
                        }
                        &.history__body__status {
                            top: 75px;
                        }
                        &.history__body__pay {
                            top: 105px;
                        }
                    }
                }
            }
        }
        .basket {
            tr {
                &.basket__body {    
                    td.basket__body__close {
                        padding: 0;
                    }
                }
            }
        }
        .warning {
            padding: 15px 20px;
        }
        .formalization {
            padding-top: 10px;
            h3 {
                margin-bottom: 20px;
            }
            span {
                font-size: 16px;
            }
            .g-recaptcha {
                margin: 15px 25px 0 0;
            }
            .btn {
                margin-top: 15px;
            }
        }
        .team {
            .team__col {
                width: 33.333%;
                padding: 0 10px;
                .team__col--info {
                    padding: 20px 15px 20px 20px;
                }
            }
        }
        .contacts {
            form {
                input[type="text"],
                input[type="email"] {
                    width: 100%;
                }
            }
            .contacts__list {
                padding-right: 0;
            }
        }
        .map {
            height: 300px;
        }
    }
    @media screen and (max-width: 680px) {
        .about .about__list {
            margin-bottom: 25px;
            .about__list__col {
                width: 100%;
                max-width: 100%;
                ul li {
                    width: 100%;
                    text-align: left;
                    text-align-last: left;
                    margin: 15px 0 0 0;
                }
            }
        }
        .cat {
            .cat__col {
                width: 50%;
            }
        }
        .cat--inside {
            .cat--inside__col {
                width: calc(50% - 20px);
            }
        }
        .basket {
            tr {
                &.basket__head {
                    display: none;
                }
                &.basket__body {
                    display: block;
                    position: relative;
                    padding-right: 80px;
                    td {
                        &.basket__body__close,
                        &.basket__body__quantity {
                            display: block;
                            position: absolute;
                            right: 0;
                            padding: 0;
                        }
                        &.basket__body__close {
                            top: 10px;
                        }
                        &.basket__body__quantity {
                            bottom: 10px;
                        }
                        &.basket__body__name {
                            padding: 10px 0;
                            a .basket--img {
                                width: 100px;
                                height: 100px;
                            }
                        }
                        &.basket__body__info {
                            display: block;
                            padding: 10px 0;
                        }
                    }
                }
            }
        }
        .team {
            .team__col {
                width: 50%;
            }
        }
        .news .news__block .news__block__col {
            width: 100%;
            min-height: 120px;
        }
    }
    @media screen and (max-width: 480px) {
        .about {
            .about__brend {
                tr td {
                    display: block;
                    width: 100%;
                    height: auto;
                    min-height: 0px;
                    padding: 20px 15px;
                }
            }
        }
        .cat {
            .cat__col {
                width: 100%;
                height: auto;
                margin-bottom: 10px;
            }
        }
        .cat--inside {
            .cat--inside__col {
                width: calc(100%);
                margin: 15px 0 0 0;
                .cat--inside__col__img {
                    height: auto;
                }
            }
        }
        .filter .dropdown {
            min-width: calc(100% - 48px);
        }
        .cat--unit {
            .cat--unit__for {
                .slick-slide {
                    height: 250px;
                }
            }
            .cat--unit__nav {
                .slick-slide {
                    height: 100px;
                }
            }
            .cat--unit__tabs {
                li a {
                    font-size: 14px;
                    padding: 10px 10px;
                }
            }
            table td {
                min-width: 0px;
            }
        }
        .tabs {
            margin: 25px 0 10px;
            ul li a {
                font-size: 14px;
                padding: 10px 10px;
            }
        }
        .cabinet {
            form {
                .cabinet__checkbox {
                    .right {
                        .checkbox {
                            display: table;
                        }
                    }
                    &.second {
                        .right {
                            .col {
                                width: auto;
                                padding-right: 0;
                            }
                        }
                    }
                }
            }
            .g-recaptcha {
                transform: scale(0.9);
                transform-origin: 0 50%;
                margin: 10px 0;
            }
            .btn {
                margin-top: 15px;
            }
            &.user {
                .tabs {
                    margin-bottom: 20px;
                }
            }
        }
        .history {
            font-size: 14px;
            tr.history__body {
                min-height: 120px;
                padding-right: 90px;
                padding-bottom: 35px;
                td {
                    &.history__body__name {
                        font-size: 14px;
                        a {
                            .history--img {
                                width: 60px;
                                height: 60px;
                                img {
                                    object-position: top center;
                                }
                            }
                            .history__title {
                                vertical-align: top;
                                padding-left: 10px;
                            }
                        }
                    }
                    &.history__body__date {
                        right: auto;
                        top: auto;
                        left: 0;
                        bottom: 10px;
                    }
                    &.history__body__delivery {
                        top: 15px;
                    }
                    &.history__body__pay {
                        top: 45px;
                    }
                    &.history__body__status {
                        top: 75px;
                    }
                }
            }
        }
        .basket {
            font-size: 14px;
            margin-bottom: 25px;
            tr {
                &.basket__body {
                    td {
                        &.basket__body__name a {
                            .basket--img {
                                width: 60px;
                                height: 60px;
                            }
                            h5 {
                                font-size: 14px;
                            }
                        }
                        &.basket__body__info {
                            padding-top: 0;
                        }
                    }
                }
            }
        }
        .formalization {
            .g-recaptcha {
                display: block;
                margin-right: 0;
            }
        }

        .team {
            .team__col {
                width: 100%;
                padding: 0;
                .team__col__img {
                    height: auto;
                }
                h5 {
                    min-height: 0px;
                }
            }
        }

        .news .news__block {
            .news__block__col {
                padding-left: 0;
                .img {
                    width: 100%;
                    height: auto;
                }
            }
        }

        .contacts {
            .contacts__list {
                li {
                    padding-left: 75px;
                    img {
                        width: 50px;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 460px) {
        .contacts {
            form {
                .g-recaptcha {
                    float: right;
                    margin-top: 0px;
                }
            }
        }
        .basket tr.basket__total td a {
            display: table;
            font-size: 16px;
            margin: 0 auto;
            &.next {
                float: none;
            }
        }
    }
    @media screen and (max-width: 380px) {
        .contacts form {
            .g-recaptcha {
                transform: scale(0.9);
                transform-origin: 100% 50%;
            }
        }
        .formalization {
            .g-recaptcha {
                transform: scale(0.9);
                transform-origin: 0 50%;
            }
        }
    }
}

/* modal */
.modal {
    &#modal--registration .modal-content {
        max-width: 520px;
    }
    .modal-dialog {
        top: 45%;
        margin: 0 auto;
        transform: translateY(-50%)!important;
    }
    .modal-content {
        width: auto;
        max-width: 420px;
        border-radius: 0px;
        margin: 0 auto;
        h4 {
            font-family: $bold;
            text-align: center;
            text-transform: uppercase;
            padding: 0 15px;
            margin: 0;
        }
        .close {
            width: 25px;
            height: 25px;
            position: absolute;
            right: 8px;
            top: 10px;
            opacity: 1;
            outline: none;
            &:before, &:after {
                content: '';
                width: 25px;
                height: 2px;
                background-color: #e40001;
                position: absolute;
                top: 50%;
                left: 0;
                right: 0;
                margin: -1px auto 0;
                transition: 300ms;
            }
            &:before {
                transform: rotate(45deg);
            }
            &:after {
                transform: rotate(-45deg);
            }
        }
    }
    form {
        display: block;
        padding-bottom: 140px;
        margin-top: 25px;
        textarea, 
        input[type="text"], 
        input[type="email"], 
        input[type="search"], 
        input[type="password"] {
            min-height: 50px;
            width: 100%;
            background-color: #fff;
            font-family: $regular;
            font-size: 20px;
            color: #000000;
            padding: 15px 20px;
            border: 1px solid #000000;
            line-height: 1;
            margin-bottom: 20px;
            &::placeholder {
                color: #333;
            }
        }
        .g-recaptcha {
            float: right;
        }
        .btn {
            float: right;
            margin: 15px 0 0 0;
        }
    }
    @media screen and (max-width: 767px) {
        .modal-content {
            h4 {
                font-size: 16px;
            }
        }
    }
    @media screen and (max-width: 420px) {
        .modal-dialog {
            margin: 0 10px;
            h4 {
                font-size: 16px;
            }
        }
    }
    @media screen and (max-width: 380px) {
        .modal-content {
            form {
                .g-recaptcha {
                    transform: scale(0.85);
                    transform-origin: 100% 50%;
                }
            }
        }
    }
}

.btn--call {
    width: 70px;
    height: 70px;
    background-color: #e40001;
    font-family: 'fontAwesome';
    font-size: 40px;
    color: #fff;
    text-align: center;
    position: fixed;
    padding-top: 14px;
    right: 0px;
    top: 50%;
    box-shadow: 0px 0px 25px rgba(0,0,0,0.5);
    margin-top: -45px;
    box-sizing: border-box;
    z-index: 2;
    &:after {
        content: '\f095';
    }
    &:focus {
        color: #fff;
    }
    &:hover {
        text-decoration: none;
        color: #fff;
        opacity: 0.7;
    }
    @media screen and (max-width: 991px) {
        width: 50px;
        height: 50px;
        font-size: 30px;
        padding-top: 10px;
        margin-top: -25px;
    }
    @media screen and (max-width: 767px) {
        width: 35px;
        height: 35px;
        font-size: 20px;
        padding-top: 7px;
        margin-top: -12px;
    }
}

.btn--up {
    display: flex;
    width: 45px;
    height: 45px;
    align-items: center;
    justify-content: center;
    font-family: 'fontAwesome';
    font-size: 20px;
    color: #fff;
    background-color: #e40001;
    position: fixed;
    bottom: 35px;
    right: 15px;
    padding: 0;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.3);
    line-height: 1;
    opacity: 0;
    outline: none;
    border-radius: 100%;
    z-index: 2;
    &:after {
        content: '\f077';
        margin-top: -2px;
    }
    &:focus {
        color: #fff;
        outline: none;
    }
    &:hover {
        text-decoration: none;
        color: #fff;
        opacity: 0.7;
    }
    @media screen and (max-width: 991px) {
        display: none;
    }
}
/* modal */

/* page content */